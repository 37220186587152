function makeIdPath(base: string) {
  return (id: string): string => {
    if (id) {
      return `${base}/${id}`
    }
    return `${base}/:id`
  }
}

/**
 * 특정 path에 둘 이상의 URL 파라미터를 추가할 때 사용하는 함수
 * 타입 파라미터 `P`에 `[type: string, id: string]`처럼 튜플 타입을 지정하면
 * `siteUrl`을 사용하는 컴포넌트에서 전달해야할 파라미터의 개수와 타입을 알 수 있으므로 설정하는 것을 추천.
 * @param base
 */
function makeMultipleParams<P extends string[]>(base: string) {
  return (...params: P) => {
    if (params.length > 0) {
      return `${base}/${params.join('/')}`
    }
    return `${base}/:id`
  }
}

function makeProjectIdPath(base: string, path: string) {
  return (id: string): string => {
    if (id) {
      return `${base}/${id}/${path}`
    }
    return `${base}/:id`
  }
}

export const SiteUrls = {
  Landing: '/',
  User: {
    _: '/user',
    List: '/user/list',
    Detail: makeMultipleParams<[id: string]>('/user/detail'),
    Signup: '/user/signup',
  },
  Login: {
    _: '/login',
  },
  Marketing: {
    _: '/marketing',
    BulkMail: '/marketing/bulk-mail',
  },
  Task: {
    _: '/task',
    List: '/task/list',
  },
  AINode: {
    _: '/aiNode',
    List: '/aiNode/list',
  },
  Metrics: {
    _: '/metrics',
    Usage: '/metrics/usage',
  },
}
