import React, { useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

import { CampThemeProvider } from '@ahha/theme/CampTheme'
import Notification from '@ahha/stableComponents/Notification/Notification'
import { I18nProvider } from '@ahha/i18n/i18n'
import routes from '@/routes/routes'
import { NotificationProvider } from '@/context/notificationContext'
import { QueryProvider } from '@/api/react-query/queryClient'
import i18nConfig from '@/locale/i18nConfig'
import { availableThemes } from '@/theme/ThemeConfig'

import classes from './App.module.scss'
import { RootState } from '@/stores'
import { setCurrentLanguage, setCurrentTheme } from '@/stores/slices/configSlice'

const shouldForwardProp = (propName: string, target: unknown) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  return true
}

function App() {
  const currentConfig = useSelector((state: RootState) => state.config)
  const dispatch = useDispatch()

  const router = useMemo(() => createBrowserRouter(routes()), [])

  const willChangeLanguage = (lang: string) => {
    dispatch(setCurrentLanguage(lang))
  }

  const setThemeId = (theme: string) => {
    dispatch(setCurrentTheme(theme))
  }

  return (
    <QueryProvider>
      <I18nProvider
        availableLanguages={i18nConfig}
        currentLanguage={currentConfig.currentLanguage}
        setCurrentLanguage={willChangeLanguage}
      >
        <CampThemeProvider
          availableThemes={availableThemes}
          themeId={currentConfig.currentTheme}
          setThemeId={setThemeId}
        >
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <NotificationProvider>
              <Notification />
              {/* TODO: styled component로 변경 */}
              <div className={classes.App}>
                <div className={classes.layoutWrapper}>
                  <RouterProvider router={router} />
                </div>
              </div>
            </NotificationProvider>
          </StyleSheetManager>
        </CampThemeProvider>
      </I18nProvider>
    </QueryProvider>
  )
}

export default App
