import BaseApi from '@/api/Base/BaseApi'
import {
  CheckEmailVerificationPayload,
  LoginPayload,
  LoginResponse,
  SendEmailVerificationPayload,
  SignUpPayload,
  UpdateInfoPasswordPayload,
  UpdatePasswordPayload,
  UpdateUserPayload,
  UserInfo,
  CreateUserPayload,
  GetUserParams,
  GetUserPayload,
  UserDetailResponse,
  SendEmailPayload
} from '@/api/User/types'

const ROOT = '/admin'

export default class UserApi extends BaseApi {
  login(payload: LoginPayload) {
    return this.agent.post<LoginResponse>(`${ROOT}/sign-in`, payload)
  }

  getAllUsers(params: GetUserParams) {
    return this.agent.get<GetUserPayload>(`${ROOT}/user/list`, { params })
  }

  getInvitationUsers(params: GetUserParams) {
    return this.agent.get<GetUserPayload>(`${ROOT}/user/invitation-list`, { params })
  }

  getUserDetail(userId: string) {
    return this.agent.get<UserDetailResponse>(`${ROOT}/user/${userId}`)
  }

  createUser(payload: CreateUserPayload) {
    return this.agent.post(`${ROOT}/user/create`, payload)
  }

  updateUser(userId: string, payload: Omit<CreateUserPayload, 'email'>) {
    return this.agent.put(`${ROOT}/user/${userId}`, payload)
  }

  deleteUser(userId: string) {
    return this.agent.delete(`${ROOT}/user/${userId}`)
  }

  sendEmail(payload: SendEmailPayload) {
    return this.agent.post('/admin/send-email', payload)
  }

  isAvailableEmail(email: string) {
    return this.agent.get<boolean>('/user/is-available-email', {
      params: { email },
    })
  }

  resendInvitationEmail(invitationId: string) {
    return this.agent.post(`/invitation/${invitationId}/workspace/resend-admin`)
  }

  downloadUsageMetrics(startDate: string, endDate: string) {
    return this.agent.get<any>('/admin/user-usage-metrics', {
      params: {
        startDate,
        endDate,
      },
    })
  }

  // sendEmailVerification(payload: SendEmailVerificationPayload) {
  //   return this.agent.post(`${ROOT}/send-email-verification`, payload)
  // }

  // checkEmailVerification(payload: CheckEmailVerificationPayload) {
  //   return this.agent.post(`${ROOT}/check-email-verification`, payload)
  // }

  // signup(payload: SignUpPayload) {
  //   return this.agent.post(`${ROOT}/sign-up`, payload)
  // }

  // updatePassword(payload: UpdatePasswordPayload) {
  //   return this.agent.put(`${ROOT}/password`, payload)
  // }

  // getUserInfo() {
  //   return this.agent.get<UserInfo>(`${ROOT}/info`)
  // }

  // updateUserInfo(payload: UpdateUserPayload) {
  //   const { name, phoneNumber, profileColor, profileImage } = payload
  //   const formData = new FormData()
  //   if (name) {
  //     formData.append('name', name)
  //   }
  //   if (phoneNumber) {
  //     formData.append('phoneNumber', phoneNumber)
  //   }
  //   if (profileColor) {
  //     formData.append('profileColor', profileColor)
  //   }
  //   if (profileImage) {
  //     formData.append('profileImage', profileImage)
  //   }
  //   return this.agent.put<UserInfo>(`${ROOT}/info`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   })
  // }

  // deleteProfileImage() {
  //   return this.agent.delete<UserInfo>(`${ROOT}/profile-image`)
  // }

  // updateInfoPassword(payload: UpdateInfoPasswordPayload) {
  //   return this.agent.put(`${ROOT}/info/change-password`, payload)
  // }
}
