import React from 'react'
import { Navigate } from 'react-router-dom'
import loadable from '@loadable/component'

import { SiteUrls } from '@/routes/siteUrls'

// Layout
const LoginLayout = loadable(() => import('@/layouts/LoginLayout'))
const DefaultLayout = loadable(() => import('@/layouts/DefaultLayout'))
// login
const LoginMain = loadable(() => import('@/pages/login/Login'))
// User
const UserList = loadable(() => import('@/pages/user/List'))
const UserCreate = loadable(() => import('@/pages/user/Create'))
// Marketing
const BulkMail = loadable(() => import('@/pages/marketing/BulkMail'))
// Task
const TaskList = loadable(() => import('@/pages/task/List'))
// AI Node
const AINodeList = loadable(() => import('@/pages/aiNode/List'))

// USAGE
const Usage = loadable(() => import('@/pages/metrics/Usage'))

const routes = () => [
  {
    path: SiteUrls.Landing,
    element: <Navigate to={SiteUrls.Login._} replace />,
  },
  {
    path: SiteUrls.Login._,
    element: <LoginLayout />,
    children: [
      {
        path: '',
        element: <LoginMain />,
        children: [
          // {
          //   path: SiteUrls.login.SignUp,
          //   element: <SignUp />,
          // },
          // {
          //   path: SiteUrls.login.RecoverPassword,
          //   element: <RecoverPassword />,
          // },
        ],
      },
    ],
  },
  {
    path: SiteUrls.User._,
    element: <DefaultLayout />,
    children: [
      {
        path: SiteUrls.User.List,
        element: <UserList />,
      },
      {
        path: SiteUrls.User.Detail(':id'),
        element: <UserCreate />,
      },
      {
        path: SiteUrls.User.Signup,
        element: <UserCreate />,
      },
    ],
  },
  {
    path: SiteUrls.Marketing._,
    element: <DefaultLayout />,
    children: [
      {
        path: SiteUrls.Marketing.BulkMail,
        element: <BulkMail />,
      },
    ],
  },
  {
    path: SiteUrls.Task._,
    element: <DefaultLayout />,
    children: [
      {
        path: SiteUrls.Task.List,
        element: <TaskList />,
      },
    ],
  },
  {
    path: SiteUrls.AINode._,
    element: <DefaultLayout />,
    children: [
      {
        path: SiteUrls.AINode.List,
        element: <AINodeList />,
      },
    ],
  },
  {
    path: SiteUrls.Metrics._,
    element: <DefaultLayout />,
    children: [
      {
        path: SiteUrls.Metrics.Usage,
        element: <Usage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={SiteUrls.Login._} replace />,
  },
]

export default routes
