import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'
import { get } from 'lodash'
import { API } from '@/api'

export type UserType = {
  token: string;
  user: {
    email: string;
    name: string;
    _id: string;
  },
  isSignIn: boolean;
}

const initialState: UserType = {
  token: '',
  user: {
    email: '',
    name: '',
    _id: '',
  },
  isSignIn: false,
}

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserType>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setUserName(state, action: PayloadAction<string>) {
      return { ...state, user: { ...state.user, name: action.payload } }
    },
    setUserInfo(state, action: PayloadAction<Partial<UserType['user']>>) {
      return { ...state, user: { ...state.user, ...action.payload } }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: any) => {
      const token = get(action, 'payload.user.token')
      API().setToken(token)
    })
  },
})

export const {
  setUser,
  setUserName,
  setUserInfo,
} = user.actions
export default user.reducer
