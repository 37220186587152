import { combineReducers } from 'redux'

import Config from '@/stores/slices/configSlice'
import UserReducer from '@/stores/slices/userSlice'

const rootReducer = combineReducers({
  user: UserReducer,
  config: Config,
})

export type ReducerType = ReturnType<typeof rootReducer>
export default rootReducer
