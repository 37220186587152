import React, { useEffect, useRef, useState } from 'react'

import ImgButton from '@ahha/stableComponents/Button/ImgButton'
import { ICON_TYPES, IconValueType, NotificationType, ToastType } from '@ahha/utils/@types/types'
import { StyledNotiItem, StyledToastItem } from '@ahha/stableComponents/Notification/styled'
import { useGlobalNotification } from '@/context/notificationContext'

import { ReactComponent as CloseIcon } from '@/assets/icons/common/close_icon.svg'
import { ReactComponent as InfoIcon } from '@/assets/icons/common/info_icon.svg'

const isInstanceofToast = (n: NotificationType | ToastType): n is ToastType => (
  Object.hasOwn(n, 'toastType')
)

const getIcon = (type: IconValueType | undefined) => {
  if (type === ICON_TYPES.INFO) {
    return <InfoIcon />
  }
  return null
}

const NotificationItem = (props: NotificationType | ToastType) => {
  const { id, autoHide, title, message } = props
  const [cleared, setCleared] = useState(false)
  const functionTimerRef = useRef<NodeJS.Timeout>()
  const stateTimeRef = useRef<NodeJS.Timeout>()

  const { removeNotification } = useGlobalNotification()

  useEffect(() => {
    if (autoHide) {
      stateTimeRef.current = setTimeout(() => {
        setCleared(true)
      }, 5000)
    }
    return () => {
      clearTimeout(stateTimeRef.current)
      clearTimeout(functionTimerRef.current)
    }
  }, [])

  useEffect(() => {
    if (cleared) {
      functionTimerRef.current = setTimeout(() => {
        removeNotification(id)
      }, 200)
    }
  }, [cleared])

  const handleClearNotification = () => {
    setCleared(true)
  }

  if (isInstanceofToast(props)) {
    const { variant, iconType } = props
    return (
      <StyledToastItem {...props} className={cleared ? 'notificationClear' : ''}>
        {getIcon(iconType)}
        <div>
          <h5>{title}</h5>
          <span>{message}</span>
        </div>
        <ImgButton src={CloseIcon} alt="clear" onClick={handleClearNotification} variant="transparent" />
      </StyledToastItem>
    )
  }

  return (
    <StyledNotiItem {...props} className={cleared ? 'notificationClear' : ''}>
      <span>{message}</span>
    </StyledNotiItem>
  )
}
export default NotificationItem
