/* eslint-disable class-methods-use-this */
/* eslint-disable no-constructor-return */
import BaseApi from '@/api/Base/BaseApi'
import UserApi from '@/api/User/UserApi'
import TaskApi from '@/api/Task/TaskApi'
import AINodeApi from '@/api/AINode/AINodeApi'

class Api extends BaseApi {
  static proto: Api

  #instance: Api | undefined

  constructor(signal?: AbortSignal, isInstance = false) {
    super(signal)
    this.#instance = this

    if (isInstance) {
      return this.#instance
    }
    if (Api.proto) {
      return Api.proto
    }
    Api.proto = this
  }

  withSignal(signal: AbortSignal) {
    return new Api(signal, true)
  }

  User = new UserApi(this.signal)

  Task = new TaskApi(this.signal)

  AINode = new AINodeApi(this.signal)
}

export const API = () => new Api()
